<template>
  <div class="app-container">
    <el-card class="form-container" shadow="never">
    <el-form>
      <el-form-item label="Reconcile by Merchant: ">
        <el-select v-model="merchantId" collapse-tags>
          <div class="el-input">
            <input type="text" class="el-input__inner" v-model="merchantSearchValue" @keyup="
              handleFilter(
                merchantOptions,
                'merchantOptionsFiltered',
                merchantSearchValue,
              )
            " />
          </div>
          <el-option v-for="item in merchantOptionsFiltered" :key="item.id" :value="item.id" :label="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit()"
          >Reconcile</el-button>
      </el-form-item>
    </el-form>
      <el-form>
        <el-form-item label="Reconcile all by start date: ">
            <el-date-picker
              v-model="startDate"
              type="date"
              placeholder="Start Date"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitReconcileAll()"
            >Reconcile</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { fetchList as fetchMerchantList } from '@/api/merchant';
import { fetchReconcileCSV, fetchReconcileAllCSV } from '@/api/order';

export default {
  name: 'reconcile',
  data() {
    return {
      merchantOptions: [],
      merchantOptionsFiltered: null,
      merchantSearchValue: null,
      merchantId: null,
      startDate: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      fetchMerchantList().then((response) => {
        this.merchantOptions = response.data.data;
        this.merchantOptions.sort((a, b) => a.name.localeCompare(b.name));
        this.merchantOptions.unshift({
          id: null,
          name: '--No Selected Merchant--',
        });
        this.merchantOptionsFiltered = this.merchantOptions;
      });
    },

    handleFilter(src, dst, keyword) {
      if (keyword == null || keyword == '') return;
      const matched = src.filter((x) => x.name.toLowerCase().includes(keyword.toLowerCase()));
      const unmatched = src.filter(
        (x) => !x.name.toLowerCase().includes(keyword.toLowerCase()),
      );
      this[dst] = matched.concat(unmatched);
    },
    handleExportCSV() {

    },
    submit() {
      this.$confirm('Reconcile orders of selected Merchant', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'Warning',
      }).then(() => {
        fetchReconcileCSV({ merchantId: this.merchantId }).then((response) => {
          const blob = new Blob([`\uFEFF${response.data}`]);
          console.log(response.data.length);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          const merchantName = this.merchantOptions.find((m) => m.id == this.merchantId).name;
          a.href = url;
          a.download = [`Reconcile_${merchantName}_${new Date()}.csv`];
          a.click();
          window.URL.revokeObjectURL(url);
        });
      });
    },
    submitReconcileAll() {
      this.$confirm('Reconcile orders from start date?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'Warning',
      }).then(() => {
        fetchReconcileAllCSV({ startDate: this.startDate }).then((response) => {
          const blob = new Blob([`\uFEFF${response.data}`]);
          console.log(response.data.length);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = [`Reconcile_All_${new Date()}.csv`];
          a.click();
          window.URL.revokeObjectURL(url);
        });
      });
    },
  },
};
</script>
